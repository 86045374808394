import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Main/index.tsx";
import Box from '@material-ui/core/Box';
import PrefillIcon from '@/assets/solutions/customer-data-prefill.webp';
import { HighlightCardWithIcon } from '@/components/HighlightCard';
import Provider from '@/components/Solutions/Provider';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Provider mdxType="Provider">
      <Box textAlign="center" paddingY={3} mdxType="Box">
        <h4 {...{
          "id": "reduce-customer-drop-off-and-increase-conversions-with-prefilled-forms",
          "style": {
            "position": "relative"
          }
        }}>{`Reduce customer drop off and increase conversions with prefilled forms.`}<a parentName="h4" {...{
            "href": "#reduce-customer-drop-off-and-increase-conversions-with-prefilled-forms",
            "aria-label": "reduce customer drop off and increase conversions with prefilled forms permalink",
            "className": "anchor after"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a></h4>
      </Box>
      <Box textAlign="left" mdxType="Box">
        <p>{`TaleFin streamlines the application process by allowing consumers to share their data with lenders to make fast, informed decisions.`}</p>
        <p>{`TaleFin can supply meta information from the financial institution to the lender so they can pre-populate application fields. The consumer can then confirm information instead of having to type it in manually.`}</p>
      </Box>
      <Box padding={4} mdxType="Box">
        <HighlightCardWithIcon icon={PrefillIcon} mdxType="HighlightCardWithIcon">
          <h5 {...{
            "id": "create-a-safe-and-easy-application-experience",
            "style": {
              "position": "relative"
            }
          }}>{`Create a safe and easy application experience`}<a parentName="h5" {...{
              "href": "#create-a-safe-and-easy-application-experience",
              "aria-label": "create a safe and easy application experience permalink",
              "className": "anchor after"
            }}><svg parentName="a" {...{
                "aria-hidden": "true",
                "focusable": "false",
                "height": "16",
                "version": "1.1",
                "viewBox": "0 0 16 16",
                "width": "16"
              }}><path parentName="svg" {...{
                  "fillRule": "evenodd",
                  "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
                }}></path></svg></a></h5>
          <p>{`Our solution drives conversions by minimizing consumer data entry requirements while verifying each consumer's particulars. Filling in application form fields such as the address, income, liabilities, housing situation, BSB and account numbers can be tiresome for customers.`}</p>
          <p>{`This ultimately affects conversion rates and opens up lenders to the possibility of human error. Instead, our solution drives conversions by minimizing consumer data entry requirements while verifying each consumer's identity.`}</p>
        </HighlightCardWithIcon>
      </Box>
    </Provider>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      